<template>
  <modal name="currency-form" :width="550" height="auto" :scrollable=true
         :clickToClose=false
         @before-open="beforeOpen"
         @before-close="beforeClose">
         <div class="form-container">
            <h4 class="title">Add a currency</h4>
            <hr>
            <form id="form" @submit.prevent="">
                <div class="currency-group">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="control-label">Currency</label>
                                <select v-validate="'required'" data-vv-name="Currency" name="currency-name" class="form-control form-control-sm" v-model="data.currency">
                                    <option v-for="currency in options.currencies" v-bind:key="currency.id" v-bind:value="currency.id">
                                        {{ currency.name }}
                                    </option>
                                </select>
                                <div class="error">{{ errors.first('Currency')}}</div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="control-label">Gateway</label>
                                <select v-validate="'required'" data-vv-name="Gateway" class="form-control-sm form-control" v-model="data.gateway">
                                    <option v-for="gateway in options.gateways" v-bind:key="gateway.id" v-bind:value="gateway.id">
                                        {{ gateway.value }}
                                    </option>
                                </select>
                                <div class="error">{{ errors.first('Gateway')}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="control-label">Customer Service Phone</label>
                                <input v-validate="'numeric'" data-vv-name="Customer Service Phone" name="customer-service-phone" class="form-control form-control-sm" v-model="data.customer_service_phone" type="text" />
                                <div class="error">{{ errors.first('Customer Service Phone')}}</div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="control-label">Direct Donation Phone</label>
                                <input v-validate="'numeric'" data-vv-name="Direct Donation Phone" class="form-control form-control-sm" v-model="data.direct_donation_phone" type="text" />
                            </div>
                            <div class="error">{{ errors.first('Direct Donation Phone')}}</div>
                        </div>
                    </div> 
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="control-label">Currency Word</label>
                                <select v-validate="'required'" data-vv-name="Currency Word" class="form-control form-control-sm" v-model="data.currency_word">
                                    <option v-for="word in options.currency_word" v-bind:key="word.id" v-bind:value="word.id">
                                        {{ word.value }}
                                    </option>
                                </select>
                                <div class="error">{{ errors.first('Currency Word') }}</div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="control-label">Currency Words</label>
                                <select v-validate="'required'" data-vv-name="Currency Words" class="form-control form-control-sm" v-model="data.currency_words">
                                    <option v-for="words in options.currency_words" v-bind:key="words.id" v-bind:value="words.id">
                                        {{ words.value }}
                                    </option>
                                </select>
                                <div class="error">{{ errors.first('Currency Words') }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="control-label">Country ISO Code</label>
                        <select v-validate="'required'" data-vv-name="Country ISO Code" class="form-control form-control-sm" v-model="data.country_iso_code">
                            <option v-for="iso_code in options.country_iso_codes" v-bind:key="iso_code.id" v-bind:value="iso_code.value">
                                {{ iso_code.value }}
                            </option>
                        </select>
                        <div class="error">{{ errors.first('Country ISO Code') }}</div>
                    </div>
                </div>
                <div class="row">
                    <div class="col col-md-12">
                        <button @click="onClick" class="float-md-right  btn btn-primary">Save</button>                      
                        <button @click="closeModal" class="float-md-left btn btn-secondary">Cancel</button>                      
                    </div>
                </div>
            </form>
        </div>
    </modal>
</template>

<script>
export default {
  data() {
    return {
        callback: () => {},
        options: {
            languages: [],
            currencies: [],
            gateways: [],
            currency_word: [],
            currency_words: [],
            country_iso_codes: []
        },
        data: {
            currency: '',
            gateway: '',
            customer_service_phone: '',
            direct_donation_phone: '',
            currency_word: '',
            currency_words: '',
            country_iso_code: ''
        }
    }
  },
  props: {
      someFn: Function
  },
  methods: {
    closeModal() {
        if (confirm("Close without saving?")) {
            this.reset();
            this.$children[0].toggle();
        } else {
            return false;
        }
        
    },
    reset() {
        this.data = {
            currency: '',
            gateway: '',
            customer_service_phone: '',
            direct_donation_phone: '',
            currency_word: '',
            currency_words: '',
            country_iso_code: ''
        };
    },
    onClick() {
        this.$validator.validate().then(isValid => {
            if (!isValid) {
                return false;
            }

            this.callback(this.data);
            this.reset();

            this.$children[0].toggle();
        });



        // this.$root.$emit('close');
    },
    beforeOpen (event) {
        this.$http.get('/data.json').then(res => {
            this.options = res.body;
        })
        this.callback = event.params.callback;
    },
    beforeClose () {}
  },
  created: function() {}
}
</script>

<style scoped>
.form-container {
    padding: 20px;
}

.error {
    color: #ff0000;
    font-size: 11px;
}
</style>
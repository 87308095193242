<template>
<div>
    <div class="row">
        <div class="col-md-12">
            <h4 class="title">Languages</h4>
            <table class="table table-striped">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Language</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(lang, index) in this.languages" v-bind:key="index" v-bind:value="lang">
                        <td>{{ index + 1}}</td>
                        <td>{{ lang.value }}</td>
                        <td><button @click="removeLanguage(index)" class="float-md-right btn btn-link">Remove</button></td>
                    </tr>
                </tbody>
            </table>

            <div class="form-group">
                <label class="control-label">Add a Language</label>
                <select @change="addLanguage" class="form-control" v-model="default_language">
                    <option v-for="language in options.languages" v-bind:value="language.value" v-bind:key="language.id">
                        {{ language.name }}
                    </option>
                </select>
            </div>
            <h4 class="title">Currencies</h4>
            <div v-if="currencies.length === 0">
                <div class="alert alert-warning">Add currency &amp; a language to generate JSON</div>
            </div>
            <div v-if="currencies.length > 0">
                <table class="table table-striped">
                <thead>
                    <tr>
                        <th>Currency</th>
                        <th>Gateway</th>
                        <th>Customer Service Phone</th>
                        <th>Direct Donation Phone</th>
                        <th>Currecncy Word</th>
                        <th>Currency Words</th>
                        <th>Country ISO Code</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(currency, idx) in currencies" v-bind:key="idx" v-bind:value="currency">
                        <td>{{ currency.currency }}</td>
                        <td>{{ currency.gateway }}</td>
                        <td>{{ currency.customer_service_phone }}</td>
                        <td>{{ currency.direct_donation_phone }}</td>
                        <td>{{ currency.currency_word }}</td>
                        <td>{{ currency.currency_words }}</td>
                        <td>{{ currency.country_iso_code }}</td>
                        <td>
                            <button @click="removeCurrency(idx)" class="float-md-right btn btn-link">Remove</button>
                        </td>
                    </tr>
                </tbody>
                </table>
            </div>
            <div>
                <div class="float-md-right">
                    <button @click="btn" class="btn btn-primary btn-sm">Add Currency</button>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <div class="code-output card">
                <div class="card-header">
                    JSON Output
                </div>
                <div class="card-body" v-if="languages.length > 0 && currencies.length > 0">
                    <button v-if="languages.length > 0" @click="copy" class="btn btn-dark btn-sm">Copy</button>
                    <textarea id="code" readonly="true" v-model="jsonOutput" class="form-control"></textarea>
                </div>
                <div class="card-body" v-if="languages.length === 0 || currencies.length === 0">
                    <div class="alert alert-warning">Add currency &amp; a language to generate JSON</div>
                </div> 
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: "Form",
    data() {
        return {
            output: {
                value: true,
                default_language: '',
                languages: [],
                currencies: []
            },
            outputStr: '',
            default_language: '',
            languages: [],
            currencies: [],
            options: {
                languages: []
            }
        }
    },
    props: {
    },
    computed: {
        jsonOutput: function() {
            // this.output.default_language = this.languages;
            let langs = {};
            for (var i in this.languages) {
                langs[Number(i) +1] = this.languages[i].value;
            }

            // eslint-disable-next-line
            this.output.default_language = this.languages.length > 0 ? this.languages[0].value : '';
            // eslint-disable-next-line
            this.output.languages = langs;
            // eslint-disable-next-line
            this.output.currencies = this.currencies;

            return JSON.stringify(this.output, null, 2);
        }
    },
    methods: {
        removeCurrency(idx) {
            this.currencies.splice(idx, 1);
        },
        removeLanguage(idx) {
            this.languages.splice(idx, 1);
        },
        addLanguage() {
            for (var lang of this.languages) {
                if (lang.value === this.default_language) {
                    return false;
                }
            }

            this.languages.push({ id: this.languages.length+1, value: this.default_language });
            this.default_language = '';
        },
        copy() {
            let code = document.getElementById('code');

            code.select();

            document.execCommand("copy");
        },
        updateOutput(newData) {
            this.currencies.push(newData);
            this.output.default_language = this.default_language;
            this.output.currencies = this.currencies;
            this.outputStr = JSON.stringify(this.output, null, 2);
        },
        btn() {
            let self = this;
            this.$modal.show("currency-form", {
                callback: function(data) {
                    self.updateOutput(data);
                }
            });
        },
        submit() { }
    },
    beforeCreate: function() {
        this.$http.get('/data.json').then((res) => {
            this.options.languages = res.body.languages;
        });
    },
    created: function() {}
}
</script>
<style scoped>
.code-output {
    margin-top: 16px;
}

#code {
    font-family: monospace;
    font-size: 12px;
    height: 350px;
}
.title {
    padding-bottom: 10px;
}
</style>

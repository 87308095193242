<template>
  <div id="app" class="container">
    <Form />
    <currency-form />
  </div>
</template>

<script>
import Form from './components/Form.vue';
import CurrencyForm from './components/CurrencyForm.vue';
export default {
  name: 'app',
  components: {
    Form,
    CurrencyForm
  }
}
</script>

<style>
#app {
}
</style>

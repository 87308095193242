import Vue from 'vue'
import App from './App.vue'
import VueModal from 'vue-js-modal';
import VueResource from 'vue-resource';
import VeeValidate from 'vee-validate';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap';

Vue.config.productionTip = false
Vue.use(VueModal, { dynamic: true, injectModalsContainer: true });
Vue.use(VueResource);
Vue.use(VeeValidate, {
  events: 'change|blur'
});

new Vue({
  render: h => h(App),
}).$mount('#app');
